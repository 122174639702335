import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { paths } from 'routing';
import { useInView } from 'react-intersection-observer';
import { useAppDispatch } from 'state/hooks';
import { track } from 'state/app';
import Link from 'next/link';
import { Breakpoint } from '@internal/ui/css/breakpoints';
import { globalBodyPrimary, globalCtaLarge } from '@internal/ui/typography';
import { semanticColor } from '@internal/ui/palette/semantic';
import { focusVisible } from '@internal/ui/css/focusVisible';

interface FooterProps {
    className?: string;
    color?: string;
    onVisibilityChange?: (visible: boolean) => void;
}

const Footer = (props: FooterProps) => {
    const { className, color = semanticColor('text-secondary'), onVisibilityChange } = props;
    const [ref, inView] = useInView({
        rootMargin: '100px',
    });

    const dispatch = useAppDispatch();

    const handleLinkClick = (link: string) =>
        dispatch(
            track({
                event: 'Click Footer Link',
                link,
            })
        );

    useEffect(() => {
        if (onVisibilityChange) {
            onVisibilityChange(inView);
        }
    }, [inView, onVisibilityChange]);

    return (
        <FooterStyled className={className} ref={ref} color={color}>
            <Inner>
                <SectionColumnStyled>
                    <LinkColumnStyled>
                        <ul>
                            <li>
                                <LinkStyled
                                    href={paths.about()}
                                    title="About"
                                    target="_blank"
                                    rel="noopener"
                                    onClick={() => handleLinkClick('about')}
                                >
                                    About
                                </LinkStyled>
                            </li>
                            <li>
                                <Link href="/jobs">
                                    <LinkStyled
                                        href="/jobs"
                                        title="Careers"
                                        onClick={() => handleLinkClick('careers')}
                                    >
                                        Careers
                                    </LinkStyled>
                                </Link>
                            </li>
                            <li>
                                <LinkStyled
                                    rel="noopener"
                                    href="https://medium.com/@WatchPlayback"
                                    target="_blank"
                                    onClick={() => handleLinkClick('blog')}
                                >
                                    Blog
                                </LinkStyled>
                            </li>
                            <li>
                                <LinkStyled
                                    rel="noopener"
                                    href="https://www.twitter.com/watchplayback"
                                    target="_blank"
                                    onClick={() => handleLinkClick('twitter')}
                                >
                                    Twitter
                                </LinkStyled>
                            </li>
                            <li>
                                <LinkStyled
                                    rel="noopener"
                                    href="https://discord.gg/vBkzkH2ptQ"
                                    target="_blank"
                                    onClick={() => handleLinkClick('discord')}
                                >
                                    Discord
                                </LinkStyled>
                            </li>
                        </ul>
                    </LinkColumnStyled>
                    <LinkColumnStyled>
                        <ul>
                            <li>
                                <LinkStyled
                                    href="mailto:contact@playback.tv?subject=Playback Beta"
                                    title="Contact"
                                    target="_blank"
                                    onClick={() => handleLinkClick('contact')}
                                >
                                    Contact
                                </LinkStyled>
                            </li>
                            <li>
                                <LinkStyled
                                    href={paths.faq()}
                                    title="FAQ"
                                    target="_blank"
                                    rel="noopener"
                                    onClick={() => handleLinkClick('faq')}
                                >
                                    FAQ
                                </LinkStyled>
                            </li>
                            <li>
                                <LinkStyled
                                    href="https://www.notion.so/playback/Creator-U-9fd278ce7cd244b89de0a370bf59f90c"
                                    title="Creator U"
                                    target="_blank"
                                    rel="noopener"
                                    onClick={() => handleLinkClick('creator-u')}
                                >
                                    Creator U
                                </LinkStyled>
                            </li>
                            <li>
                                <Link href="/demo">
                                    <LinkStyled
                                        href="/demo"
                                        title="Creator Demo"
                                        onClick={() => handleLinkClick('demo')}
                                    >
                                        Creator Demo
                                    </LinkStyled>
                                </Link>
                            </li>
                            <li>
                                <LinkStyled
                                    href="https://www.youtube.com/playlist?list=PL06zhgtnp1mwkSwFW_tkoH9oWPxMPLX1E"
                                    title="Playback 101"
                                    target="_blank"
                                    rel="noopener"
                                    onClick={() => handleLinkClick('playback-101')}
                                >
                                    Playback 101
                                </LinkStyled>
                            </li>
                        </ul>
                    </LinkColumnStyled>
                    <LinkColumnStyled>
                        <ul>
                            <li>
                                <LinkStyled
                                    href={paths.terms()}
                                    title="Terms"
                                    target="_blank"
                                    rel="noopener"
                                    onClick={() => handleLinkClick('terms')}
                                >
                                    Terms
                                </LinkStyled>
                            </li>
                            <li>
                                <LinkStyled
                                    href={paths.copyright()}
                                    title="Copyright"
                                    target="_blank"
                                    rel="noopener"
                                    onClick={() => handleLinkClick('copyright')}
                                >
                                    Copyright
                                </LinkStyled>
                            </li>
                            <li>
                                <LinkStyled
                                    href={paths.privacy()}
                                    title="Privacy"
                                    target="_blank"
                                    rel="noopener"
                                    onClick={() => handleLinkClick('privacy')}
                                >
                                    Privacy
                                </LinkStyled>
                            </li>
                        </ul>
                    </LinkColumnStyled>
                </SectionColumnStyled>
                <CopyrightColumnStyled>
                    <CopyrightStyled>Built in NYC & SF</CopyrightStyled>
                    <CopyrightStyled>{`© ${new Date().getFullYear()} Playback Inc.`}</CopyrightStyled>
                </CopyrightColumnStyled>
            </Inner>
        </FooterStyled>
    );
};

const CopyrightColumnStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: ${rem(48)};

    @media screen and (min-width: ${rem(Breakpoint.Small)}) {
        margin-top: 0;
    }
`;

const LinkStyled = styled.a`
    ${globalCtaLarge}
    transition: color 50ms ease;
    display: flex;
    align-items: center;

    img {
        height: ${rem(16)};
        width: ${rem(16)};
        margin-left: ${rem(8)};
    }
    ${focusVisible}
    &:focus-visible {
        border-radius: ${rem(2)};
    }

    @media (hover: hover) {
        &:hover {
            color: ${semanticColor('text-primary')};
            text-decoration: underline;
            text-underline-offset: ${rem(4)};
        }
    }
`;

const CopyrightStyled = styled.p`
    ${globalBodyPrimary}

    & + & {
        margin-top: ${rem(8)};
    }

    @media screen and (min-width: ${rem(Breakpoint.Small)}) {
        margin: 0;
    }
`;

const LinkColumnStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;

    li + li {
        margin-top: ${rem(8)};
    }
`;

const SectionColumnStyled = styled.div`
    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
    flex: 1;
    box-sizing: border-box;
    gap: ${rem(16)};

    @media screen and (min-width: ${rem(Breakpoint.Small)}) {
        display: flex;
        justify-content: flex-start;
        max-width: none;
        gap: ${rem(80)};
    }
`;

const Inner = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-top: auto;
    align-self: stretch;
    box-sizing: border-box;
    flex: 1;

    @media screen and (min-width: ${rem(Breakpoint.Small)}) {
        width: 100%;
        margin: 0 auto;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
    }
`;

const FooterStyled = styled.div<{ color: string }>`
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    box-sizing: border-box;
    color: ${({ color }) => color};
`;

export default Footer;
