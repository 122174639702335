const paths = {
    home: () => '/',
    terms: () => 'https://legal.playback.tv/terms',
    privacy: () => 'https://legal.playback.tv/privacy',
    copyright: () => 'https://legal.playback.tv/copyright',
    faq: () => 'https://about.playback.tv/faq',
    about: () => 'https://about.playback.tv',
    careers: () => 'https://about.playback.tv/careers',
};

export default paths;
